<template>
  <div>
    <div id="building">
      <!--    头部-->
      <div style="width: 100%; border-bottom: 1px solid #000000">
        <div class="titol">
          <a href="http://www.tsjm.ln.cn/">
            <img src="../../assets/logo_home.png" style="width: 400px; position: relative; top: 5px; right: 0">
          </a>
        </div>
      </div>
      <div :class="{'item':!isPhone,'itemPhone':isPhone}" style="padding-left: 30px ;padding-top: 10px">
        <el-descriptions v-if="!isPhone" :direction="direction" title="产品信息" :column="4">
          <el-descriptions-item label="产品型号" span="2">{{ serialName }}</el-descriptions-item>
<!--          <el-descriptions-item label="生产单位" span="2">凤城市天盛精密机械制造股份有限公司</el-descriptions-item>-->
<!--          <el-descriptions-item label="生产日期" span="2">{{ outtime }}</el-descriptions-item>-->
          <el-descriptions-item label="联系电话" span="2">
            <el-button size="mini" type="text" @click="copy('13358782338')" >133 5878 2338</el-button>
            <el-button size="mini" type="text" @click="copy('13352162338')" >133 5216 2338</el-button>
          </el-descriptions-item>
<!--          <el-descriptions-item label="厂   址" span="4">辽宁凤城市二龙山工业园区B区10号</el-descriptions-item>-->
          <el-descriptions-item v-for="(item,index) in children" :label="item.indexName" span="1">{{item.value}}</el-descriptions-item>
        </el-descriptions>

        <el-descriptions v-if="isPhone" :direction="false" title="产品信息" :column="2">
          <el-descriptions-item label="产品型号" span="2">{{ serialName }}</el-descriptions-item>
<!--          <el-descriptions-item label="生产日期" span="2">{{ outtime }}</el-descriptions-item>-->
          <el-descriptions-item v-for="(item,index) in children" :label="item.indexName" span="2">{{item.value}}</el-descriptions-item>
          <el-descriptions-item label="联系电话" span="2">
            <a @click="copy('13358782338')" >133 5878 2338</a><br>
            <a @click="copy('13352162338')" >133 5216 2338</a>
          </el-descriptions-item>
<!--          <el-descriptions-item label="生产单位" span="2">凤城市天盛精密机械制造股份有限公司</el-descriptions-item>-->
<!--          <el-descriptions-item label="厂   址" span="2">辽宁省凤城市二龙山工业园区B区10号</el-descriptions-item>-->
        </el-descriptions>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Front",

  data() {
    return {
      screenWidth: document.body.clientWidth,
      direction:"horizontal",
      isPhone:false,
      serialName:"",
      outtime:"2020.04.17",
      children:{},

      xuhao:"18100000000",
      query:"",
    };
  },
  created() {

  },
  mounted() {
    this.getQueryParams()
    this.requestQRCode()
    let that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch:{
    screenWidth: {
      immediate: true,
      handler: function (newVal){
        this.getIsPhone(newVal)
      }
    },
  },
  methods: {
    getIsPhone(newVal){
      if (newVal<800){
        this.isPhone = true
        this.direction = "vertical"
      } else {
        this.direction = "horizontal"
        this.isPhone = false
      }
    },
    async copy(phone) {
      this.copyData=phone
      var oInput = document.createElement('input');
      oInput.value = this.copyData;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display='none';
      this.$message.success("复制成功!")
    },
    getQueryParams() {
      let query = location.search.substring(1)
      let arr = query.split('&')
      let params = {}
      for (var i = 0; i < arr.length; i++) {
        var pair = arr[i].split('=')
        params[pair[0]] = pair[1]
      }
      this.query = params.serial;
    },
    requestQRCode(){
      request.post("/requestQRCode",this.query).then(res => {
        this.serialName = res.data.serialName;
        this.outtime = res.data.outtime;
        this.children = res.data.children;
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '查询失败'
        });
      }
    }
  }
}
</script>

<style>
.titol {
  display: flex;
  width: 40%;
  padding-left: 30px;
  line-height: 60px;
}
.item {
  width: 720px
}
.itemPhone {
  width: 90%
}
</style>
